// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
/*
 * import { getAnalytics } from "firebase/analytics";
 *  TODO: Add SDKs for Firebase products that you want to use
 *  https://firebase.google.com/docs/web/setup#available-libraries
 */

/*
 * Your web app's Firebase configuration
 * For Firebase JS SDK v7.20.0 and later, measurementId is optional
 */
const firebaseConfig = {
  apiKey: 'AIzaSyA3LiUywPAAHA2H52xmvJ9hRhkPxI2yXbE',
  authDomain: 'coordsgo.firebaseapp.com',
  databaseURL: 'https://coordsgo-default-rtdb.firebaseio.com',
  projectId: 'coordsgo',
  storageBucket: 'coordsgo.appspot.com',
  messagingSenderId: '1405412348',
  appId: '1:1405412348:web:d149a74fd0532f6e30c879'
}

// Initialize Firebase
const appFire = initializeApp(firebaseConfig)
//const analytics = getAnalytics(appFire);

export const app = appFire
