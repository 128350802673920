export function localStorageExpires() {
    var toRemove = [],
        currentDate = new Date().getTime()

    for (var i = 0, j = localStorage.length; i < j; i++) {
        var key = localStorage.key(i),
            value = localStorage.getItem(key)

        if (value && value[0] === "{" && value.slice(-1) === "}") {

            var current = JSON.parse(value)

            if (current.expires && current.expires <= currentDate) {
                toRemove.push(key)
            }
        }
    }

    for (var iz = toRemove.length - 1; iz >= 0; iz--) {
        localStorage.removeItem(toRemove[iz])
    }
}


export function setLocalStorage(chave, valor, minutos) {
    var expirarem = new Date().getTime() + (60000 * minutos)

    localStorage.setItem(chave, JSON.stringify({
        "value": valor,
        "expires": expirarem,
        "count": 5
    }))
}

export function getLocalStorage(chave) {
    localStorageExpires()

    var value = localStorage.getItem(chave)

    if (value && value[0] === "{" && value.slice(-1) === "}") {

        var current = JSON.parse(value)

        return current.value
    }
}

export function getLocalStorageCount(chave) {
    localStorageExpires()

    var value = localStorage.getItem(chave)

    if (value && value[0] === "{" && value.slice(-1) === "}") {

        var current = JSON.parse(value)

        return current.count
    }
}