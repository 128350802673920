import Vue from 'vue'
import VueRouter from 'vue-router'
import routerPt from './routerPt'
import routerEn from './routerEn'
import routerEs from './routerEs'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [...routerPt, ...routerEn, ...routerEs],
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    }
})

export default router
