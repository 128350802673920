export default [
    {
        path: '/en',
        component: () => import('../templates/en.vue'),
        children: [
            {
                path: '',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'en',
                    titulo: 'Quest'
                }
            },
            {
                path: 'quest',
                component: () => import('../views/Missao.vue')
            },
            {
                path: 'coordinates/:id',
                component: () => import('../views/Missao.vue')
            },
            {
                path: 'pkm100',
                name: 'CoordsEn',
                component: () => import('../views/Pokemon100.vue'),
                meta: {
                    titulo: 'Coords',
                    lang: 'en'
                }
            },
            {
                path: 'quest/:id',
                name: 'missaoIdEn',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'en'
                }
            },
            {
                path: 'quest/:id/link',
                name: 'missaoIdLinkEn',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'en'
                }
            },
            {
                path: 'missoes/:id',
                component: () => import('../views/Missao.vue')
            },
            {
                path: 'gpx',
                component: () => import('../views/Gpx.vue'),
                meta: {
                    lang: 'en',
                    titulo: 'GPX'
                }
            },
            {
                path: 'clear',
                component: () => import('../views/Limpar.vue'),
                meta: {
                    lang: 'en',
                    titulo: 'Clear'
                }
            },
            {
                path: 'location',
                component: () => import('../views/Locais.vue'),
                meta: {
                    lang: 'en',
                    titulo: 'Location'
                }
            },
            {
                path: 'events',
                name: 'Events',
                component: () => import('../views/Eventos.vue'),
                meta: {
                    titulo: 'Events',
                    lang: 'en'
                }
            },
            {
                path: 'events/:id/:text',
                name: 'EventsId',
                component: () => import('../views/EventosId.vue'),
                meta: {
                    titulo: 'Events',
                    lang: 'en'
                }
            },
            {
                path: 'videos',
                name: 'videosEn',
                component: () => import('../views/Videos.vue'),
                meta: {
                    titulo: 'Vídeos',
                    lang: 'en'
                }
            },
            {
                path: 'nests',
                name: 'Nests',
                component: () => import('../views/Ninhos.vue'),
                meta: {
                    titulo: 'Nests',
                    lang: 'en'
                }
            },
            {
                path: 'rocket',
                name: 'Rocket2',
                component: () => import('../views/Rocket.vue'),
                meta: {
                    titulo: 'Rocket',
                    lang: 'en'
                }
            },
            {
                path: 'raids',
                name: 'Raids2',
                component: () => import('../views/Raids.vue'),
                meta: {
                    titulo: 'Raids',
                    lang: 'en'
                }
            },
            {
                path: 'pkm90',
                name: 'pkm90En',
                component: () => import('../views/Radar.vue'),
                meta: {
                    titulo: 'pkm90',
                    lang: 'en'
                }
            },
            {
                path: 'partners',
                name: 'partners',
                component: () => import('../views/Parceiros.vue'),
                meta: {
                    titulo: 'pkm',
                    lang: 'en'
                }
            }
        ]
    }
]
