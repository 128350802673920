export default [
    {
        path: '/es',
        component: () => import('../templates/es.vue'),
        children: [
            {
                path: '',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'es',
                    titulo: 'Misión'
                }
            },
            {
                path: 'mision',
                component: () => import('../views/Missao.vue')
            },
            {
                path: 'coordinates/:id',
                component: () => import('../views/Missao.vue')
            },
            {
                path: 'mision/:id/link',
                name: 'missaoIdLinkEs',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'es'
                }
            },
            {
                path: 'pkm100',
                name: 'CoordsEs',
                component: () => import('../views/Pokemon100.vue'),
                meta: {
                    titulo: 'Coords',
                    lang: 'es'
                }
            },
            {
                path: 'mision/:id',
                name: 'missaoIdEs',
                component: () => import('../views/Missao.vue'),
                meta: {
                    lang: 'es'
                }
            },
            {
                path: 'missoes/:id',
                component: () => import('../views/Missao.vue')
            },
            {
                path: 'gpx',
                component: () => import('../views/Gpx.vue'),
                meta: {
                    lang: 'es',
                    titulo: 'GPX'
                }
            },
            {
                path: 'limpio',
                component: () => import('../views/Limpar.vue'),
                meta: {
                    lang: 'es',
                    titulo: 'Limpio'
                }
            },
            {
                path: 'lugares',
                component: () => import('../views/Locais.vue'),
                meta: {
                    lang: 'es',
                    titulo: 'Lugares'
                }
            },
            {
                path: 'eventos',
                name: 'EventosEs',
                component: () => import('../views/Eventos.vue'),
                meta: {
                    titulo: 'Eventos',
                    lang: 'es'
                }
            },
            {
                path: 'eventos/:id/:text',
                name: 'EventosIdEs',
                component: () => import('../views/EventosId.vue'),
                meta: {
                    titulo: 'Eventos',
                    lang: 'es'
                }
            },
            {
                path: 'videos',
                name: 'videosEs',
                component: () => import('../views/Videos.vue'),
                meta: {
                    titulo: 'Videos',
                    lang: 'es'
                }
            },
            {
                path: 'nidos',
                name: 'Nidos',
                component: () => import('../views/Ninhos.vue'),
                meta: {
                    titulo: 'Nidos',
                    lang: 'es'
                }
            },
            {
                path: 'rocket',
                name: 'Rocket1',
                component: () => import('../views/Rocket.vue'),
                meta: {
                    titulo: 'Rocket',
                    lang: 'es'
                }
            },
            {
                path: 'raids',
                name: 'RaidsEs',
                component: () => import('../views/Raids.vue'),
                meta: {
                    titulo: 'Raids',
                    lang: 'es'
                }
            },
            {
                path: 'pkm90',
                name: 'pkm90Es',
                component: () => import('../views/Radar.vue'),
                meta: {
                    titulo: 'pkm90',
                    lang: 'es'
                }
            },
            {
                path: 'socios',
                name: 'socios',
                component: () => import('../views/Parceiros.vue'),
                meta: {
                    titulo: 'pkm',
                    lang: 'es'
                }
            }
        ]
    }
]
