export function linkParser(url) {
  let result
  if (url) {
    try {
      const slug = new URL(setHttp(url))
      const host = slug.hostname.toLowerCase().replace(/^(www|m)\./, "")
      if (host == "coordsgo.com") {
        result = slug
      } else if (host == "coordsgo.com") {
        result = new URL("/", window.location.origin)
        result.searchParams.set("v", slug.pathname.split("/")[1])
      }
    } finally { // eslint-disable-line
      return result instanceof URL ? result : false // eslint-disable-line
    }
  }
}

function setHttp(link) {
  if (link.search(/^http[s]?\:\/\//) == -1) { // eslint-disable-line
    link = "http://" + link
  }
  return link
}